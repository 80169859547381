import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { CompanyInfoSettingFormStyles } from './styled';
import { validateFullWhitespace } from 'utils/validation/validatorHelper';
import {
    FocusEventHandler,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import { PatternFormat, NumericFormat } from 'react-number-format';
import { alertSuccess, validateZipcode } from 'utils/helper/appHelper';
import companyInfoService from 'services/companyInfoService';

type FormValues = {
    company_name: string;
    zipcode: string;
    address: string;
    fax: string;
    bank_number: string;
};

const CompanyInfoSettingForm: React.FC = () => {
    const {
        control,
        formState: { errors },
        setValue,
        clearErrors,
        getValues,
        setError,
        handleSubmit,
    } = useForm<FormValues>({
        defaultValues: {
            company_name: '',
            zipcode: '',
            address: '',
            fax: '',
            bank_number: '',
        },
    });
    const findZipcodeTimer = useRef<NodeJS.Timeout>();
    const [submitLoading, setSubmitLoading] = useState(false);

    const getCompanyOwnerInfo = useCallback(async () => {
        try {
            const data = await companyInfoService.getCompanyInfo();
            if (data) {
                setValue(
                    'company_name',
                    data.owner_company?.company_name || '',
                );
                setValue('zipcode', data.owner_company?.zipcode || '');
                setValue('address', data.owner_company?.address || '');
                setValue('fax', data.owner_company?.fax || '');
                setValue('bank_number', data.owner_company?.bank_number || '');
            }
        } catch (error) {
            //
        }
    }, []);

    useEffect(() => {
        getCompanyOwnerInfo();
    }, []);

    const handleValidateWhitespace: FocusEventHandler<
        HTMLInputElement | HTMLTextAreaElement
    > = (e) => {
        if (
            e.target.name === 'company_name' ||
            e.target.name === 'fax' ||
            e.target.name === 'zipcode' ||
            e.target.name === 'address' ||
            e.target.name === 'bank_number'
        ) {
            const onlyContainWhitespaces = validateFullWhitespace(
                e.target.value,
            );
            if (onlyContainWhitespaces) {
                setValue(e.target.name, '');
            }
        }
    };

    const parsePostalCode = (zipcode: string) => {
        if (!zipcode) {
            setError('zipcode', {
                type: 'required',
                message: '郵便番号を入力してください。',
            });
            return;
        }

        const isZipcodeFormat = validateZipcode(zipcode);
        if (!isZipcodeFormat) {
            setError('zipcode', {
                type: 'validate',
                message: '郵便番号は無効な形式です。',
            });
            return;
        }

        if (findZipcodeTimer.current) {
            clearTimeout(findZipcodeTimer.current);
        }

        let foundAddress: string;
        const postalCode = require('japan-postal-code');
        postalCode.get(zipcode, function (address) {
            foundAddress = `${address?.prefecture}${address?.city}${address?.area}`;
        });

        findZipcodeTimer.current = setTimeout(() => {
            if (foundAddress) {
                setValue('address', foundAddress);
                clearErrors('address');
                clearErrors('zipcode');
            } else {
                setValue('address', '');
                setError('zipcode', {
                    type: 'validate',
                    message: '入力された郵便番号に該当する住所が存在しません。',
                });
            }
        }, 1000);
    };

    const submitData: SubmitHandler<FormValues> = async (data) => {
        setSubmitLoading(true);
        if (data.zipcode) {
            const isZipcodeFormat = validateZipcode(data.zipcode);
            if (!isZipcodeFormat) {
                setError('zipcode', {
                    type: 'validate',
                    message: '郵便番号は無効な形式です。',
                });
                return;
            }
        }
        let formData = new FormData();
        formData.append('company_name', data.company_name);
        formData.append('zipcode', data.zipcode);
        formData.append('address', data.address);
        formData.append('fax', data.fax);
        formData.append('bank_number', data.bank_number);

        try {
            const data = await companyInfoService.createCompanyInfo(formData);

            if (data) {
                alertSuccess('保存に成功しました');
                getCompanyOwnerInfo();
            }
        } catch (error) {
            //
        } finally {
            setSubmitLoading(false);
        }
    };

    return (
        <CompanyInfoSettingFormStyles>
            <form onSubmit={handleSubmit(submitData)}>
                <div className="w-full flex flex-col gap-y-[14px]">
                    <div className="flex flex-col w-full">
                        <Controller
                            name="company_name"
                            control={control}
                            render={({ field }) => {
                                return (
                                    <div className="d-flex flex-column">
                                        <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                            会社名
                                        </p>
                                        <input
                                            autoComplete="off"
                                            type="text"
                                            className="textBox"
                                            placeholder="会社名"
                                            {...field}
                                            onBlur={handleValidateWhitespace}
                                        ></input>
                                        {errors.company_name && (
                                            <span className="error text-xs tracking-tight mt-1">
                                                {errors.company_name.message?.toString()}
                                            </span>
                                        )}
                                    </div>
                                );
                            }}
                        />
                    </div>

                    <div className="flex flex-col w-full">
                        <Controller
                            name="zipcode"
                            control={control}
                            render={({ field, fieldState }) => {
                                return (
                                    <div className="d-flex flex-column">
                                        <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                            郵便番号
                                        </p>
                                        <div className="flex">
                                            <PatternFormat
                                                className="textBox"
                                                format="###-####"
                                                placeholder="xxx-xxxx"
                                                mask="_"
                                                {...field}
                                                onChange={(e) => {
                                                    clearErrors('zipcode');
                                                    field.onChange(e);
                                                }}
                                            />
                                            <button
                                                className="searchAddressBtn"
                                                type="button"
                                                onClick={() => {
                                                    if (fieldState.error) {
                                                        return;
                                                    }
                                                    parsePostalCode(
                                                        getValues(
                                                            'zipcode',
                                                        ) as string,
                                                    );
                                                }}
                                            >
                                                住所自動入力
                                            </button>
                                        </div>
                                        {errors.zipcode && (
                                            <span className="error text-xs tracking-tight mt-1">
                                                {errors.zipcode.message?.toString()}
                                            </span>
                                        )}
                                    </div>
                                );
                            }}
                        />
                    </div>

                    <div className="flex flex-col w-full">
                        <Controller
                            name="address"
                            control={control}
                            render={({ field }) => (
                                <div className="d-flex flex-column">
                                    <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                        住所
                                    </p>
                                    <input
                                        autoComplete="off"
                                        type="text"
                                        className="textBox"
                                        placeholder="住所"
                                        {...field}
                                        onBlur={handleValidateWhitespace}
                                    ></input>
                                    {errors.address && (
                                        <span className="error text-xs tracking-tight mt-1">
                                            {errors.address.message?.toString()}
                                        </span>
                                    )}
                                </div>
                            )}
                        />
                    </div>

                    <div className="flex flex-col w-full">
                        <Controller
                            name="fax"
                            control={control}
                            render={({ field }) => (
                                <div className="d-flex flex-column">
                                    <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                        Fax
                                    </p>
                                    <NumericFormat
                                        className="textBox"
                                        allowLeadingZeros
                                        {...field}
                                        allowNegative={false}
                                    />
                                    {errors.fax && (
                                        <span className="error text-xs tracking-tight mt-1">
                                            {errors.fax.message?.toString()}
                                        </span>
                                    )}
                                </div>
                            )}
                        />
                    </div>

                    <div className="flex flex-col w-full">
                        <Controller
                            name="bank_number"
                            control={control}
                            render={({ field }) => (
                                <div className="d-flex flex-column">
                                    <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                        銀行口座
                                    </p>
                                    <input
                                        autoComplete="off"
                                        type="text"
                                        className="textBox"
                                        placeholder="銀行口座"
                                        {...field}
                                        onBlur={handleValidateWhitespace}
                                    ></input>
                                    {errors.bank_number && (
                                        <span className="error text-xs tracking-tight mt-1">
                                            {errors.bank_number.message?.toString()}
                                        </span>
                                    )}
                                </div>
                            )}
                        />
                    </div>
                </div>

                <div className="d-flex flex-row justify-center mt-[35px]">
                    <button
                        className="modal-create-user-button2 d-flex flex-row !rounded-sm"
                        style={{ marginLeft: '10px', width: '167.5px' }}
                        type="submit"
                    >
                        保存
                    </button>
                </div>
            </form>
        </CompanyInfoSettingFormStyles>
    );
};

export default CompanyInfoSettingForm;
